import React, { useState } from 'react';
import './App.css';

function App() {
  const [message, setMessage] = useState('Are you okay?');
  const [yesButtonDimensions, setYesButtonDimensions] = useState({
    width: 100,
    height: 50,
  });
  const [showButtons, setShowButtons] = useState(true);

  const handleNoClick = () => {
    setYesButtonDimensions({
      width: yesButtonDimensions.width + 10,
      height: yesButtonDimensions.height + 10,
    });
  };

  const handleYesClick = () => {
    setMessage('Cuteeee Suellll');
    setShowButtons(false);
  };

  return (
    <div className="App">
      <div className="box">
        <div className="lightbar"></div>
        <div className="toplayer"></div>
        <h2>{message}</h2>
      </div>
      {showButtons && (
        <div className="button-container">
          <button className="no-button" onClick={handleNoClick}>No</button>
          <button
            className="yes-button"
            onClick={handleYesClick}
            style={{
              width: `${yesButtonDimensions.width}px`,
              height: `${yesButtonDimensions.height}px`,
            }}
          >
            Yes
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
